<template>
  <div class="dzs-transactions">
    <div class="row">

      <div class="col-lg-6" v-show="!registeredDevice">
        <card align="center" style="height: 100%; padding: 5%">
          <h4 slot="header" class="card-title">
            Segurança pra <span class="text-primary">@</span>você!
          </h4>
          <span style="font-size: 15px;">Para validar suas transações siga os passos abaixo:</span><br>

          <p style="margin-top: 30px"><b>1</b> - Baixe e instale o Aplicativo <span style="color: black"><b style="color: #000000 !important;">3RBank</b>.</span></p>
          <p><b>2</b> - Abra o Aplicativo e clique no botão <b>3RAuth</b>.</p>
          <p><b>3</b> - Leia o QR Code ao lado.</p>
          <p><b>4</b> - Pronto, seu aplicativo está habilitado para uso!</p>

          <div class="row" style="padding: 5% 10% 5% 10%">
            <div class="col-md-6">
              <a  href="https://apps.apple.com/br/app/suitpay/id1632130123" target="_blank">
                <img src="/static/img/mobile/apple_store.png" alt="..."  >

              </a>
            </div>
            <div class="col-md-6">
              <a href="https://play.google.com/store/apps/details?id=com.suitpay" target="_blank">
                <img src="/static/img/mobile/google_play.png" alt="..."  >
              </a>
            </div>
          </div>
        </card>
      </div>

      <div class="col-lg-6"  v-show="!registeredDevice">
        <card align="center" class="bg-primary" style="height: 100%">
          <h4 slot="header" class="card-title" style="color: #fff; text-align: center">Ative o 3RAuth</h4>
          <card style="height: 100%;  padding: 5%">
            <div class="col-md-12" style="text-align: center">
              <qrcode :value="this.qrCode" :options="{ width: 200, margin:0 }"
                      v-if="this.code"></qrcode><br><br>
              <span style="font-size: 15px;">Após a leitura do QR Code o código <b>3RAuth</b> será solicitado em todas as transações.</span>
            </div>
          </card>
        </card>
      </div>



      <div class="col-lg-6" v-show="registeredDevice">
        <card align="center" style=" padding: 5%; height: 100%">
          <h4 slot="header" class="card-title">
            Tudo <span class="text-primary">Pronto</span>!
          </h4>
          <span style="font-size: 15px;">Dispositivo de segurança ativado com sucesso!</span><br><br>

          <span style="font-size: 15px;">Seu dispositivo de segurança <b>3RAuth</b> foi ativado e você pode continuar com suas transações com total segurança e comodidade.</span><br><br>
          <span style="font-size: 15px;">O <b>3RAuth</b> garante que você faça consulta e transações nos canais <b>3RBank</b>.</span><br><br>
          <span style="font-size: 15px;">Tenha cuidado e não passe essa informação a ninguém.</span><br><br>
          <span style="font-size: 15px;">Caso queira registrar outro dispositivo, favor entrar em contato com o nosso suporte.</span><br>
          <img src="/static/img/mobile/apple_google.png" alt="..."  >
        </card>
      </div>

      <div class="col-lg-6"  v-show="registeredDevice">
        <card  class="bg-primary" style="height: 100%;">
          <h4 slot="header" class="card-title" style="color: #fff; text-align: center">O 3RAuth já está ativado!</h4>
          <card style=" text-align: center; padding: 5%">
            <div class="row">
              <div class="col-lg-6">
                <img style="max-width: 70%" src="/static/img/3rauth-cellphone.png" alt="..."  ><br><br>
                <span style="font-size: 15px;">Dispositivo registrado: <b>{{this.modelDevice}}</b>.</span>
              </div>

              <div class="col-lg-6">
                <img style="max-width: 70%" src="/static/img/3rauth-code.png" alt="..."  ><br><br>
                <span style="font-size: 15px;">A <b>3RBank</b> nunca entra em contato solicitando este código.</span><br><br>
                <span style="font-size: 15px;">O <b>3RAuth</b> é variável e encontrado no aplicativo. Sempre que for realizar uma nova transação é preciso ser informado.</span>
              </div>
            </div>
          </card>
        </card>
      </div>


    </div>
  </div>
</template>
<script>
  import {postWs, getWs, failWs} from "src/ws.service";

  export default {
    components: {},
    data() {
      return {
        code: '',
        qrCode: '',
        statusIntervalLoop: null,
        newCodeIntervalLoop: null,
        registeredDevice: false,
        modelDevice: '',
      }
    },
    mounted() {
      this.getNewCode()
      this.newCodeIntervalLoop = setInterval(() => {
        this.getNewCode()
      }, 110 * 1000)

    },
    methods: {
      getNewCode() {
        getWs("/device/new-code",
          true,
          null,
          (response) => {
          if(response.data.model != null) {
            this.registeredDevice = true
            this.modelDevice = response.data.model
          } else {
            clearInterval(this.statusIntervalLoop)
            this.code = response.data.totpManualCode
            this.qrCode = response.data.totpManualCode
            this.loadStatusLoop()
          }
          },
          function (error) {
          })
      },
      getStatusCode() {
        postWs("/device/code-status",
          true,
          null,
          {code: this.code},
          (response) => {
            localStorage.setItem('verifiedDevice', true)
            clearInterval(this.newCodeIntervalLoop)
            clearInterval(this.statusIntervalLoop)
            this.registeredDevice = true
            this.modelDevice = response.data.model
            return
          },
          (error) => {
            if (this.$router.currentRoute.name != 'Device') {
              clearInterval(this.newCodeIntervalLoop)
              clearInterval(this.statusIntervalLoop)
              return
            }
            if (error.response.status !== 401
              && error.response.status !== 400) {
              failWs(error)
            }
          })
      },
      loadStatusLoop() {
        this.statusIntervalLoop = setInterval(() => {
          this.getStatusCode()
        }, 5 * 1000)
      }
    }
  }
</script>
<style lang="scss">
  .dzs-device {

  }
</style>

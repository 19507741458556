<template>
  <div class="dzs-tev">
    <div class="row" style="padding: 15px">
      <div class="col-md-12" >
        <div class="row">
          <div class="col-md-5" v-if="!showHistory">
            <card style="padding: 5%; height: 100%">
              <h4 slot="header" class="card-title">
                Transferência entre Contas
              </h4>
              <p><i class="fas fa-arrow-circle-right text-primary"></i> Ao informar o nome de usuário (@nome), verifique as informações cadastrais antes de validar a transação.<br>
                <i class="fas fa-arrow-circle-right text-primary"></i> Transferências ilimitadas.</p>
            </card>
          </div>

          <div class="col-md-7" v-if="!showHistory && isNullOrEmpty(transfer.qrCode)">
            <card style="height: 100%">
              <div class="row">
                <div class="col-md-12">
                  <div style="float: left; width: 84%">
                    <label class="control-label">Transferir para</label>
                    <label class="control-label" style="float: right">
                      <a href="#" @click="loadHistory">
                        <i slot="label" class="fas fa-history"></i> Histórico de Favorecidos</a>
                    </label>
                    <fg-input v-model="transfer.destination" style="width: 100%">
                      <template slot="addonLeft">@</template>
                    </fg-input>
                    <label class="control-label">Valor</label>
                    <fg-input placeholder="0,00" v-money="'money'" type="tel"
                              v-model="transfer.value">
                      <template slot="addonLeft">R$</template>
                    </fg-input>
                    <label class="control-label" style="float: left">
                      Taxa Serviço: <b>Isento</b>
                    </label>
                    <label class="control-label" style="float: right">
                      Saldo: <b>R$ {{toMoney(accountBalance)}}</b>
                      <br>
                      Saldo disponível para saque: <b>R$ {{toMoney(accountAvailableBalance)}}</b>
                    </label>
                  </div>
                  <p-button class="search" style="margin-top: 25px; float: left" @click="search">
                    <i slot="label" class="fas fa-search"></i>
                  </p-button>
                </div>

                <div class="col-md-12" v-if="!isNullOrEmpty(transfer.destinationName)">
                  <br>
                  <div class="col-md-12" v-if="!isNullOrEmpty(transfer.destinationName)" style="text-align: center">
                    <div class="col-sm-12">
                      <label class="control-label">Nome Destino</label>
                      <p class="form-control-static">{{transfer.destinationName}}</p>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="!isNullOrEmpty(transfer.destinationSocialNumber)" style="text-align: center">
                    <div class="col-sm-12">
                      <label class="control-label">CPF/CNPJ</label>
                      <p class="form-control-static">{{transfer.destinationSocialNumber}}</p>
                      <input v-mask="['###.***.***-##', '##.###.###/####-##']" v-model="transfer.destinationSocialNumber"
                             style="display: none"/>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="!isNullOrEmpty(transfer.email)" style="text-align: center">
                    <div class="col-sm-12">
                      <label class="control-label">E-mail Destinatário</label>
                      <p class="form-control-static">{{transfer.email}}</p>
                    </div>
                  </div>
                </div>

                <hr v-if="!isNullOrEmpty(transfer.destinationName)"/>
                <div class="col-md-12" v-if="!isNullOrEmpty(transfer.destinationName)" style="text-align: center">
                  <div class="col-sm-12">
                    <label class="control-label">Descrição</label>
                    <fg-input v-model="transfer.description" style="max-width: 200px; margin: 0 auto" maxlength="20"/>
                    <br>
                    <br>
                    <p-button type="info" @click="doTransaction" style="margin: 0 auto; display: table; min-width: 260px; height: 80px">
                      <i slot="label" class="fas fa-exchange-alt"></i> Transferir
                    </p-button>
                  </div>
                </div>
                <hr v-if="!isNullOrEmpty(transfer.destinationName)"/>
              </div>
            </card>
          </div>

          <!-- Bloco Dispositivo Não habilitado -->
          <div class="col-md-7"  v-if="!isNullOrEmpty(totpQrCodeUri) && !isNullOrEmpty(transfer.qrCode) && transactionAuthorizationTotp==='true'">
            <card class="bg-primary" style="text-align: center">
              <h4 slot="header" class="card-title" style="color: #fff">Seu dispositivo não está cadastrado!</h4>
              <card align="center" >
                <span>Para liberar suas transações siga os passos abaixo:</span>
                <div class="row"  v-if="isNullOrEmpty(totpQrCodeUri)">
                  <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
                </div>

                <div class="row" style="padding: 3%">
                  <div class="col-md-12" style="text-align: center" v-if="!isNullOrEmpty(totpQrCodeUri)">
                    <p style="margin-top: 30px"><b>1</b> - Baixe e instale o Aplicativo <span style="color: black"><b style="color: #000000 !important;">3RBank</b>.</span></p>
                    <p><b>2</b> - Abra o Aplicativo e clique no botão <b>3RAuth</b>.</p>
                    <p><b>3</b> - Escaneie o QR Code encontrado no menu: <b>Habilitar Aplicativo</b>.</p>
                    <p><b>4</b> - Pronto, seu aplicativo está habilitado para uso!</p><br>

                    <span>Para download do aplicativo 3RBank acesse:</span><br><br>
                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                      <img border="0" alt="Android" src="/static/img/mobile/google_play.png" style="width: 120px">
                    </a>
                    <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                      <img border="0" alt="Android" src="/static/img/mobile/apple_store.png" style="width: 120px">
                    </a>
                  </div>

                  <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                    <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <span style="color: black; font-weight: bold">Google Authenticator</span></p>
                    <p><b>2</b> - Procure por <b style="color: #000000 !important;">3RBank</b> e seu nome de usuário no aplicativo </p>
                    <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo </p>
                    <p><b>4</b> - Confirme a operação</p>
                    <p><b>5</b> - Pronto!</p>
                  </div>
                </div>
                <br>
                <div style="text-align: center">
                  <p-button @click="()=>{transfer.qrCode = ''; clearInterval(validateIntervalLoop)}"
                            class="btn btn-danger" >
                    <i class="fas fa-chevron-left"/> Voltar
                  </p-button>
                </div>
              </card>
            </card>
          </div>

          <!-- Bloco Dispositivo Habilitado -->
          <div class="col-md-7"  v-if="!isNullOrEmpty(transfer.qrCode) && transactionAuthorizationTotp==='true' && isNullOrEmpty(totpQrCodeUri)">
            <card class="bg-primary" style="text-align: center; height: 100%">
              <h4 slot="header" class="card-title" style="color: #fff">Confirmação de Transação</h4>
              <card align="center" style="height: 100%" >

                <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>

                <div class="row" style="padding: 3%">
                  <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                    <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <b>3RBank</b>.</p>
                    <p><b>2</b> - Selecione a opção <b>3RAuth</b>.</p>
                    <p><b>3</b> - Informe, no campo abaixo, o código exibido pelo aplicativo.</p>
                    <p><b>4</b> - Confirme a operação.</p>
                    <p><b>5</b> - Pronto!</p>
                  </div>
                </div>

                <span>Informe o <b>3RAuth</b>: </span>
                <input style="margin: 0 auto; margin-top: 14px" v-mask="['### ###']" v-model="validationCode" size="6"/>

                <div class="row" v-if="!codeValid">
                  <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
                </div>

                <div style="text-align: center">
                  <p-button @click="()=>{transfer.qrCode = ''; clearInterval(validateIntervalLoop)}"
                            class="btn btn-danger" >
                    <i class="fas fa-chevron-left"/> Voltar
                  </p-button>
                  <p-button @click="()=>{validateCode()}"
                            class="btn btn-primary"
                            :disabled="totpConfirmBtnDisabled"
                  >
                    <i class="fas fa-chevron-right"/> Confirmar
                  </p-button>
                </div>
              </card>
            </card>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <card v-if="showHistory">
          <h4 slot="header" class="card-title">
            Selecione um Favorecido
          </h4>
          <div class="row clickable-rows">
            <div class="col-md-12">
              <div class="col-sm-12 mt-2">
                <el-table class="table-striped"
                          :data="tableData"
                          @row-click="selectDestination"
                          style="width: 100%">
                  <el-table-column :min-width="100" label="Usuário">
                    <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.destination}}</b></span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="130" label="Nome">
                    <template slot-scope="props">
                    <span>
                      <span><b>{{props.row.destinationName}}</b></span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="CPF/CNPJ">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.destinationSocialNumber}}</span>
                    </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="col-sm-12">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.totalNumber"
                              :click="this.loadData">
                </p-pagination>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p-button type="info" style="margin: 0 auto; margin-top: 40px; display: table"
                        @click="showHistory = false">
                <i slot="label" class="fas fa-undo"></i> Voltar
              </p-button>
            </div>
          </div>
        </card>
      </div>
    <input type="hidden" id="authentication-copy" :value="authentication">
    </div>

    <div class="col-md-12" >
      <card>
        <div class="card-body row">
          <div class="col-sm-12" align="center">
            <h4 slot="header" class="card-title">
              Relação de TEV's enviadas
            </h4>
            <hr>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="timelineItems"
                      style="width: 100%">
              <el-table-column :min-width="130" label="Data">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.date}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="130" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span>@{{props.row.to}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="110" label="Valor">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.value)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="110" label="Descrição">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.userDescription}}</span>
                    </span>
                </template>
            </el-table-column>
              <el-table-column :min-width="40">
               <template slot-scope="props">
                 <span>
                   <p-button @click="printReceipt(props.row.idTransaction)"
                             class="btn btn-info" title="Ver Comprovante">
                     <i class="fas fa-file-invoice fa-2x" style="color: #0c072d;"/>
                   </p-button>
                 </span>
               </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-success">
            <p class="category">EXIBINDO DO {{paginationItems.fromNumber}} AO {{paginationItems.toNumber}}, DE
              {{paginationItems.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="paginationItems.currentPage"
                          :per-page="paginationItems.perPage"
                          :total="paginationItems.totalNumber"
                          :click="this.loadHistoryTev">
            </p-pagination>
          </div>
        </div>
      </card>
    </div>

  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {isNullOrEmpty, moneyToFloat, notNull, toMoney} from "src/util/core.utils"
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import nprogress from "nprogress";
  import DzsTevTransferDetails from "../../../UIComponents/Dzs/Transfer/DzsTevTransferDetails";
  import Banner from "../../../UIComponents/Promotion/Banner";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      DzsTevTransferDetails,
      PPagination,
      Banner
    },
    data() {
      return {
        timelineItems: [],
        paginationItems: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        authentication: '',
        validateIntervalLoop: null,
        accountBalance: '0,00',
        accountAvailableBalance: '0,00',
        transferToLegalPerson: false,
        transfer: {
          qrCode: '',
          destination: '',
          destinationName: '',
          destinationSocialNumber: '',
          value: '',
          description: '',
          email: '',
        },
        showHistory: false,
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        propsToSearch: ['destination', 'destinationName', 'destinationSocialNumber'],
        transactionAuthorizationEmail: 'false',
        transactionAuthorizationTotp: 'false',
        validationCode: '',
        totpQrCodeUri : '',
        totpManualCode : '',
        totpConfirmBtnDisabled: false,
        codeValid: true
      }
    },
    created() {
      this.accountBalance = notNull(localStorage.getItem('accountBalance'), '0,00')
      this.accountAvailableBalance = notNull(localStorage.getItem('accountAvailableBalance'), '0,00')
      this.transactionAuthorizationEmail = notNull(localStorage.getItem('transactionAuthorizationEmail'), 'false')
      this.transactionAuthorizationTotp = notNull(localStorage.getItem('transactionAuthorizationTotp'), 'false')
      this.loadHistoryTev()
    },
    methods: {
      copyAuthenticationCode() {
        let authenticationCopy = document.querySelector('#authentication-copy')
        authenticationCopy.setAttribute('type', 'text')
        authenticationCopy.select()

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          if(msg == 'successful'){
            swal({
              text: 'Código de autenticação copiado!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            })
          }else{
            swal({
              text: 'Erro ao copiar código de autenticação!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        } catch (err) {
          swal({
            text: 'Erro ao copiar código de autenticação!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }

        /* unselect the range */
        authenticationCopy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      search() {
        this.transfer.destinationName = ''
        this.transfer.destinationSocialNumber = ''
        postWs("/acc/find",
          true, {username: this.transfer.destination}, null,
          (response) => {
            this.transfer.destinationName = response.data.destinationName
            this.transfer.destinationSocialNumber = response.data.destinationSocialNumber
            this.transfer.email = response.data.email
            if(!isNullOrEmpty(response.data.destinationSocialNumber) && response.data.destinationSocialNumber.length > 11) {
              this.transferToLegalPerson = true
            } else {
              this.transferToLegalPerson = false
            }
          }, (error) => {
          console.log(error.response)
            this.transfer.destinationName = ''
            this.transfer.destinationSocialNumber = ''
            swal({
              title: 'Aviso!',
              text: error.response.data.message,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          })
      },
      doTransaction() {
        if (moneyToFloat(this.transfer.value) > moneyToFloat(this.accountBalance)) {
          swal({
            title: 'Aviso!',
            text: 'Você não possui saldo suficiente.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        } else if (moneyToFloat(this.transfer.value) <= 0) {
          swal({
            title: 'Aviso!',
            text: 'O valor tem que ser maior que ZERO!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        if(this.transferToLegalPerson) {
          swal({
            text: "O 3RBank é apenas uma facilitadora de pagamentos, regulamentada pela Lei 12.865/13. " +
              "Portanto, sempre confira a legalidade da negociação realizada. " +
              "Não nos responsabilizamos por negócios entre terceiros, nem mesmo por qualidade ou entrega de produto.",
            type: 'warning',
            buttonsStyling: true,
            confirmButtonClass: 'btn-success btn-fill',
            showCancelButton: true,
            confirmButtonColor: '#001a3f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Transferir',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result) {
              this.callTransaction()
            }
          })
        } else {
          this.callTransaction()
        }
      },
      callTransaction(){
        this.transfer.description = this.transfer.description.toUpperCase()
        postWs("/transaction/tev",
          true, null,
          {
            destination: this.transfer.destination,
            value: moneyToFloat(this.transfer.value),
            description: this.transfer.description
          },
          (response) => {
            let text = response.data.message
            if (response.data.response === 'QR_CODE_VALIDATION_REQUIRED'
              && !isNullOrEmpty(response.data.qrCode)) {
              this.transfer.qrCode = response.data.qrCode
              this.validateIntervalLoop = setInterval(
                this.callStatusWs, 5 * 1000)
              return
            } else if (response.data.response === 'EMAIL_CODE_VALIDATION_REQUIRED'
              && !isNullOrEmpty(response.data.qrCode)) {
              this.transfer.qrCode = response.data.qrCode
              return
            } else if (response.data.response === 'TOTP_CODE_VALIDATION_REQUIRED' && !isNullOrEmpty(response.data.qrCode)) {
              this.transfer.qrCode = response.data.qrCode
              this.totpQrCodeUri = response.data.totpQrCodeUri
              this.totpManualCode = response.data.totpManualCode
              return
            } else if (response.data.response === 'OK') {
              this.success(response.data.authentication)
              return
            }
            swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }, (error) => {
            let text = error.response.data.message ?? 'Serviço temporariamente indisponível! Tente novamente mais tarde.';
            swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          })
      },
      callStatusWs() {
        if (this.$router.currentRoute.name != 'Tev') {
          clearInterval(this.validateIntervalLoop)
          return
        }
        postWs("/verifcode/status",
          true,
          null,
          {verifCode: this.transfer.qrCode},
          this.transferSuccess,
          function (error) {
          })
      },
      transferSuccess(response) {
        if (response.data.status == 'USED') {
          this.success(response.data.authentication)
        }
      },
      validateCode(){
        this.totpConfirmBtnDisabled = true
        postWs("/verifcode/validate",
          true,
          null,
          {verifCode: this.transfer.qrCode,
                 hashCode: this.validationCode},
          this.confirmCode,
          this.failValidateCode)
      },
      failValidateCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Aviso!',
          text: 'Código inválido!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      confirmCode(response){
        postWs("/verifcode/confirm",
          true,
          null,
          {verifCode: response.data.verifCode},
          (response) => {
            this.success(response.data.authentication)
          },
          this.failConfirmCode)
      },
      success(authentication){
        this.totpConfirmBtnDisabled = false
        this.authentication = authentication
        clearInterval(this.validateIntervalLoop)
        this.accountBalance = String(moneyToFloat(this.accountBalance) - moneyToFloat(this.transfer.value))
        localStorage.setItem('accountBalance', this.accountBalance)

        this.transfer.value = ''
        this.transfer.destination = ''
        this.transfer.destinationName = ''
        this.transfer.destinationSocialNumber = ''
        this.transfer.description = ''
        this.transfer.qrCode = ''
        this.validationCode = ''
        this.transfer.email = ''
        this.transferToLegalPerson = false
        swal({
          title: 'Sucesso!',
          html: '<p>Código de Autenticação:<br>'+this.authentication+'</p>',
          buttonsStyling: true,
          confirmButtonClass: 'btn-success btn-fill',
          type: 'success',
          showCancelButton: true,
          confirmButtonColor: '#001a3f',
          cancelButtonColor: '#00853c',
          confirmButtonText: 'Copiar Código',
          cancelButtonText: 'OK'
        }).then((result) => {
          if (result) {
            this.copyAuthenticationCode()
          }
        })
        this.loadData()
        nprogress.done()
      },
      failConfirmCode({response}) {
        this.totpConfirmBtnDisabled = false
        this.transfer.value = ''
        this.transfer.destination = ''
        this.transfer.destinationName = ''
        this.transfer.destinationSocialNumber = ''
        this.transfer.description = ''
        this.transfer.qrCode = ''
        this.validationCode = ''
        this.transfer.email = ''
        this.transferToLegalPerson = false
        swal({
          title: 'Erro!',
          text: response.data.message,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      loadHistory() {
        this.showHistory = true
        postWs("/transaction/tev-condensed-history",
          true, null,
          {
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          },
          (response) => {
            this.tableData = response.data.list
          }, () => {
            this.tableData = []
          })
      },
      selectDestination(destination) {
        this.transfer.destination = destination.destination
        this.transfer.destinationName = destination.destinationName
        this.transfer.destinationSocialNumber = destination.destinationSocialNumber
        this.showHistory = false
      },
      loadData() {
        postWs("/transaction/tev-condensed-history",
          true, null,
          {
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData, failWs)
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      loadHistoryTev() {
        postWs("/transaction/tev-history",
          true, null,
          {
            pageNumber: (this.paginationItems.currentPage - 1),
            pageSize: this.paginationItems.perPage
          }, this.successLoadHistoryTev, this.failLoadHistoryTev)
      },
      successLoadHistoryTev(response) {
        this.timelineItems = response.data.list
        this.paginationItems.totalNumber = response.data.totalNumber
        this.paginationItems.pageMaxNumber = response.data.pageMaxNumber
        if (this.paginationItems.totalNumber > 0) {
          this.paginationItems.fromNumber = ((this.paginationItems.perPage * (this.paginationItems.currentPage - 1)) + 1)
          this.paginationItems.toNumber = ((this.paginationItems.fromNumber + this.timelineItems.length) - 1)
        } else {
          this.paginationItems.fromNumber = 0
          this.paginationItems.toNumber = 0
        }
      },
      failLoadHistoryTev(error) {
        this.timelineItems = []
        return
      },
      printReceipt(id) {
        postWs("/receipt/get-receipt-transactions",
          true,
          {idTransaction: id}, null,
          this.successPrintReceipt,
          (error) => {
            swal({
              title: 'Aviso!',
              text: error.response.data.message ?? 'Erro ao gerar comprovante! Tente novamente.',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          })
      },
      successPrintReceipt(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html lang='pt-BR'><head><title>3RBank - Comprovante</title><style>body{margin: 0;}iframe{border-width: 0;}</style></head>");
          pdfWindow.document.write("<body><iframe style='width:100% ; height:100% ' src='" + this.base64PDFToBlobUrl(response.data.pdfBase64) + "'></iframe></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do comprovante, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      base64PDFToBlobUrl(base64) {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        const blob = new Blob([arr], {type: 'application/pdf'});
        return URL.createObjectURL(blob);
      },
    }
  }
</script>
<style lang="scss">
  .dzs-tev {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .picture-row {
      margin-top: 20px;
      background-color: #5e5e5e;
    }

    .picture-container {
      margin: 5px auto;
      display: table;
    }

    .picture {
      float: left;
      width: 80px;
      height: 80px;
      background-color: #999;
      border: 1px solid #e3e3e3;
      color: #fff;
      border-radius: 50%;
      overflow: hidden;
      transition: all .2s;
      -webkit-transition: all .2s;
    }

    .picture-text {
      float: right;
      margin-top: 20px;
      margin-left: 6px;
    }

    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }

    .search {
      background-color: #001a3f !important;
    }

    .search:hover {
      background-color: #000c1e !important;
    }

    .table-striped {
      .btn.btn-info.btn-default {
        float: right;
        background: none !important;
        margin: 0 !important;
        padding: 0 !important;
      }
      .btn.btn-default{
        background-color: #001a3f !important;
      }
      .btn.btn-default:hover{
        background-color: #000c1e !important;
      }
    }
  }
</style>
